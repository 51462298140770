import type { LoaderFunctionArgs } from "@remix-run/node"

import { json, redirect } from "@remix-run/node"
import { useLoaderData, useNavigate } from "@remix-run/react"
import { checkIYKUser } from "../lib/auth.server.ts"
import { LoginForm } from "../lib/ui/login-form.tsx"
import { getSafeRedirectTo } from "../lib/utils/url.server.ts"

export async function loader(args: LoaderFunctionArgs) {
  const user = await checkIYKUser(args)
  if (user) {
    throw redirect("/profile")
  }

  return json({ redirectTo: getSafeRedirectTo(args.request.url, "/profile") })
}

export default function Login() {
  const { redirectTo } = useLoaderData<typeof loader>()
  const navigate = useNavigate()

  return (
    <div className="min-h-[100dvh] flex flex-col sm:justify-center sm:items-center">
      <div className="sm:max-w-96 w-full mx-auto flex flex-col gap-6 p-4">
        <LoginForm onLoginComplete={() => navigate(redirectTo)} />
      </div>
    </div>
  )
}
